<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-9" data-toggle="collapse">
            <i class="fas fa-credit-card mr-2"></i>
            Payment Transactions
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-9">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="success" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Added {{addedAmount}} Subscription {{ addedType == 1 ? 'Credits' : 'Debits'}}</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
                <div class="form-group">
                    <label>Account ID</label>
                    <input v-model="accountId" class="form-control" type="text">
                    <validation-message v-if="!!accountMessage" :message="accountMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Site ID</label>
                    <input v-model="siteId" class="form-control" type="text">
                    <validation-message v-if="!!siteMessage" :message="siteMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Type</label>
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light" disabled>
                            {{selectedOptionName}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="option in options"
                                    class="dropdown-item pointer"
                                    :key="option.value"
                                    @click="setTypeId(option.value)"
                                    v-bind:class="{'active': option.value === selectionOptionValue}">
                                    {{option.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <validation-message v-if="!!typeMessage" :message="typeMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Description</label>
                    <input v-model="description" class="form-control" type="text">
                    <validation-message v-if="!!descriptionMessage" :message="descriptionMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Amount</label>
                    <input v-model="amountInput" @blur="processInput" class="form-control text-right" type="number">
                    <validation-message v-if="!!amountMessage" :message="amountMessage"></validation-message>
                </div>
                <div class="form-group">
                    <button @click="tryAddSubscriptionCredit" class="btn btn-primary" type="button" v-bind:disabled="adding">
                        Add
                        <span v-if="adding" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { mapActions } from 'vuex';
    import { Api } from "../../services/Api";
    import { ProductInputMixin } from "../Products/ProductInputMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";

    export default {
        name: 'SystemAdminPaymentTransactions',
        mixins: [ProductInputMixin],
        data() {
            return {
                amount: 0,
                description: '',
                accountId: '',
                siteId: '',
                success: false,
                error: '',
                adding: false,
                options: [
                    { value: 1, name: 'Credit' }
                ],
                typeId: 1,
                accountMessage: '',
                siteMessage: '',
                typeMessage: '',
                descriptionMessage: '',
                amountMessage: '',
                addedType: Number,
                addedAmount: Number
            }
        },
        computed: {
            integerAmount() {
                return this.selectionOptionValue == 1 ? this.amount : -this.amount;
            },
            subscriptionCreditAddModel() {
                return {
                    description: this.description,
                    amount: this.integerAmount,
                    siteId: this.siteId,
                    accountId: this.accountId
                }
            },
            subscriptionCreditAddModelValid() {
                const model = this.subscriptionCreditAddModel;
                return !!model.accountId && !!model.siteId && this.amount > 0 && !!model.description;
            },
            selectedOption() {
                return this.options.find(o => o.value === this.typeId);
            },
            selectionOptionValue() {
                return !!this.selectedOption ? this.selectedOption.value : 0;
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.selectedOption.name : 'Please select...';
            }
        },
        methods: {
            ...mapActions('CreditPacks', [
                'addSubscriptionCredit'
            ]),
            tryAddSubscriptionCredit() {
                const self = this;
                self.error = '';
                self.success = false;
                self.validate();
                if (!self.subscriptionCreditAddModelValid) {
                    return;
                }
                self.adding = true;
                Api.post('SubscriptionCredit', self.subscriptionCreditAddModel)
                    .then(() => {
                        self.success = true;
                        self.clearSubscriptionCreditAddModel();
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.adding = false);
            },
            clearSubscriptionCreditAddModel() {
                this.addedType = this.typeId;
                this.addedAmount = this.amount;
                this.amountInput = '';
            },
            setTypeId(value) {
                this.typeId = value;
            },
            validate() {
                const self = this;
                const model = self.subscriptionCreditAddModel
                self.accountMessage = !!model.accountId ? '' : 'Please enter Account ID.';
                self.siteMessage = !!model.siteId ? '' : 'Please enter Site ID.';
                self.amountMessage = self.amount > 0 ? '' : 'Amount must be greater than 0.';
                self.descriptionMessage = !!model.description ? '' : 'Please enter description.';
                self.typeMessage = self.typeId > 0 ? '' : 'Please select transaction type.';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
