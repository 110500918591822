<template>
    <section class="mb-3">
        <div aria-expanded="true" class="filter-header" data-target="#mf-collapse-1" data-toggle="collapse">
            {{$t('Resource')}}
            <div class="filter-value ml-auto">
                {{selectionString}}
            </div><i class="far fa-chevron-down ml-3"></i>
        </div>
        <div id="mf-collapse-1" class="collapse show">
            <div class="filter-group">
                <resource-group v-for="site in selectedSites"
                                v-model="selectedResources" 
                                :site="site" :key="site.id" 
                                :display-site-name="selectedSites.length > 1">
                </resource-group>
            </div>
        </div>
    </section>
</template>

<script>
    import ScheduleFilterResourceItem from "./ScheduleFilterResourceItem.vue";
    import { ScheduleFilterResourceMixin } from "./ScheduleFilterResourceMixin";

    export default {
        name: 'ScheduleMobileFiltersResources',
        mixins: [ScheduleFilterResourceMixin],
        computed: {
            total() {
                return this.allActiveResources.filter(r => !!this.selectedSites.find(s => s.id === r.siteId)).length;
            },
            selectionString() {
                return this.selectedResources.length > 0 ? this.selectedResources.length + '/' + this.total : this.$t('All');
            }
        },
        components: {
            'resource-group': ScheduleFilterResourceItem
        }
    }
</script>
