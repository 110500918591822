<template>
    <div>
        <div v-if="noParticipants" class="alert-wrapper px-0">
            <div role="alert" class="alert alert-icon alert-danger">
                <div class="alert-content">
                    <p>{{$t('Pets do not exist.')}}</p>
                </div>
            </div>
        </div>
        <error-banner v-if="errors" :message="errors"></error-banner>
        <div class="row">
            <div class="col-12 col-md-auto">
                <div class="client-details-left sidenav">
                    <add-schedule :id="bookingId" :participants="participants" v-model="scheduleStatus"></add-schedule>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-8">
                        <client-details :id="bookingId" :client="client"></client-details>
                        <booking-pets :id="bookingId" :client-pets="pets"></booking-pets>
                        <template v-if="!noParticipants && !noClient">
                            <collapsed-section id="payment-details" :title="$t('Payment Details')">
                                <payment-details :id="bookingId"></payment-details>
                            </collapsed-section>
                            <add-orders :id="bookingId" :participants="participants" v-model="ordersStatus"></add-orders>
                            <booking-approve :id="bookingId" :client-id="clientId" :schedule-id="addedScheduleId" :orders-status="ordersStatus" v-model="approveStatus"></booking-approve>
                        </template>
                        <booking-conflicts v-if="bookingCanApprove" :id="bookingId"></booking-conflicts>
                        <booking-suggestions v-if="bookingStatusDeclined" :id="bookingId"></booking-suggestions>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav d-flex flex-md-row flex-wrap justify-content-md-between">
            <div class="fixed-action-btns-left">
                <button @click="goBack" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
            </div>
            <template v-if="enabled">
                <template v-if="bookingCanApprove">
                    <div class="fixed-action-btns-center">
                        <button @click="handleApproveBooking" class="btn btn-primary" type="button" v-bind:disabled="loading || noClient || noParticipants">
                            {{$t('Approve')}}
                            <span v-if="loading && !declineStatus.loading" class="inline-loader"></span>
                        </button>
                        <button @click="triggerDeclineBooking" class="btn btn-secondary" type="button" v-bind:disabled="loading || !!declineStatus.loading || completed">
                            {{$t('Decline')}}
                            <span v-if="!!declineStatus.loading" class="inline-loader"></span>
                        </button>
                    </div>
                </template>
                <template v-else-if="changeStatusToExpiredAllowed">
                    <div class="fixed-action-btns-center">
                        <button @click="tryExpireBooking" class="btn btn-secondary" type="button" v-bind:disabled="expiring">
                            {{$t('Expire')}}
                            <span v-if="!!expiring" class="inline-loader"></span>
                        </button>
                    </div>
                </template>
            </template>
            <div class="fixed-action-btns-right">
                <delete-button @clicked="remove"></delete-button>
            </div>
        </div>
        <booking-decline-modal :id="bookingId" v-model="declineStatus"></booking-decline-modal>
    </div>
</template>

<script>
    import router from '../../routing';
    import { mapState, mapMutations } from 'vuex';
    import { BookingCacheMixin } from '../Shared/BookingCacheMixin';
    import { ClientCacheMixin } from '../Shared/ClientCacheMixin';
    import { PetCacheMixinV2 } from '../Shared/PetCacheMixinV2';
    import { BookingSettingsCacheMixin } from '../../mixins/Bookings/BookingSettingsCacheMixin';
    import { BookingExpireMixin } from './BookingExpireMixin';
    import BookingClientDetails from './BookingClientDetails.vue';
    import BookingPets from './BookingPets.vue';
    import BookingAddAppointmentSchedule from './ExistingClient/BookingAddAppointmentSchedule.vue';
    import BookingAddAppointmentScheduleOrders from './ExistingClient/BookingAddAppointmentScheduleOrders.vue';
    import BookingConflicts from './BookingConflicts.vue';
    import BookingSuggestions from './BookingSuggestions.vue';
    import BookingApprove from './BookingApprove.vue';
    import BookingDeclineModal from './BookingDeclineModal.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import CollapsedSection from '../Shared/Layout/CollapsedSection.vue';
    import BookingPaymentDetails from './Payments/BookingPaymentDetails.vue';

    export default {
        name: 'BookingAuthenticated',
        mixins: [BookingCacheMixin, BookingSettingsCacheMixin, ClientCacheMixin, PetCacheMixinV2, BookingExpireMixin],
        data() {
            return {
                ordersStatus: {},
                scheduleStatus: {},
                approveStatus: {},
                declineStatus: {},
                addedScheduleId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'appointmentScheduleAddedEvent'
            ]),
            bookingId() {
                return this.$route.params.id;
            },
            clientId() {
                return !!this.booking ? this.booking.clientId : ''
            },
            participants() {
                return this.bookingParticipants.length > 0 ? this.bookingParticipants.filter(participant => {
                    return this.pets.some(pet => pet.id === participant.petId);
                }) : [];
            },
            noParticipants() {
                return this.participants.length === 0 && !this.loadingPets;
            },
            noClient() {
                return !this.client && !this.loadingClient;
            },
            statuses() {
                return [
                    this.ordersStatus,
                    this.scheduleStatus,
                    this.approveStatus
                ]
            },
            completed() {
                return this.statuses.every(s => !!s.completed);
            },
            loading() {
                return this.statuses.some(s => !!s.loading) || !!this.loadingBooking || !!this.loadingClient || !!this.loadingPets || !!this.declineStatus.loading
            },
            errors() {
                const statusErrors = this.statuses.map(s => s.error).filter(error => !!error);
                if (!!this.error) {
                    statusErrors.push(this.error);
                }
                return statusErrors.join(' ');
            }
        },
        watch: {
            booking() {
                this.setSite();
            },
            appointmentScheduleAddedEvent(schedule) {
                this.addedScheduleId = schedule.id
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            ...mapMutations('ApplicationEvents', [
                'triggerApproveBooking',
                'triggerDeleteBooking',
                'triggerDeclineBooking'
            ]),
            handleApproveBooking() {
                if (!!this.noClient) {
                    return;
                }
                this.triggerApproveBooking();
            },
            goBack() {
                router.push({ name: 'bookings' });
            },
            setSite() {
                if (!!this.booking) {
                    this.setSelected(this.booking.siteId);
                }
            },
            remove() {
                this.triggerDeleteBooking();
            }
        },
        components: {
            'client-details': BookingClientDetails,
            'booking-pets': BookingPets,
            'add-schedule': BookingAddAppointmentSchedule,
            'add-orders': BookingAddAppointmentScheduleOrders,
            'booking-conflicts': BookingConflicts,
            'booking-suggestions': BookingSuggestions,
            'booking-approve': BookingApprove,
            'booking-decline-modal': BookingDeclineModal,
            'error-banner': ErrorBanner,
            'delete-button': DeleteButton,
            'payment-details': BookingPaymentDetails,
            'collapsed-section': CollapsedSection
        },
        created() {
            if (!this.bookingId) {
                router.push({ name: 'bookings' });
            } else {
                this.setSite();
            }
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 768px) {
        .fixed-action-btns-left {
            width: 50%;
            order: 2;
            text-align: left;
        }

        .fixed-action-btns-center {
            width: 100%;
            order: 1;
            text-align: center;
            padding-bottom: 0.5rem;
        }

        .fixed-action-btns-right {
            width: 50%;
            order: 3;
            text-align: right;
        }
    }
</style>
