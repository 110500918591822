<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Hours')}}</label>
                </div>
                <div class="col-md-8">
                    <label class="ps-toggle-switch">
                        <input v-model="isWorking" class="toggle-checkbox" type="checkbox" checked="">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t(toggleText)}}</span>
                    </label>
                    <br />
                    <div v-if="isWorking" class="mw-400">
                        <div class="d-flex px-0">
                            <div class="col-6 px-0 pr-1">
                                <label for="title">{{$t('Start')}}</label>
                                <vue-timepicker v-model="startLocal" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                            </div>
                            <div class="col-6 px-0 pl-1">
                                <label for="title">{{$t('End')}}</label>
                                <vue-timepicker v-model="endLocal" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                            </div>
                        </div>
                        <validation-message v-if="workingHourValidator.startLocal" :message="workingHourValidator.startLocal"></validation-message>
                    </div>
                </div>
            </div>
        </section>
        <button @click="save" v-bind:disabled="loadingWorkingHour" class="btn btn-primary" type="button">
            {{$t('Save')}}
            <span v-if="loadingWorkingHour" class="inline-loader"></span>
        </button>
    </div>
</template>

<script>
    import { WorkingHoursUploadMixin } from '../../Shared/WorkingHours/WorkingHoursUploadMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

    export default {
        name: 'UploadWorkingHours',
        mixins: [WorkingHoursUploadMixin],
        computed: {
            toggleText() {
                return this.dayOff ? 'Day Off' : 'Working';
            },
            isWorking: {
                get() {
                    return !this.dayOff;
                },
                set(value) {
                    this.dayOff = !value;
                }
            }
        },
        methods: {
            save() {
                this.tryUploadWorkingHour();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            VueTimepicker
        }
    }
</script>
