<template>
    <table-mobile @next="next">
        <report-mobile v-for="(checkout, index) in checkouts"
                       :key="index"
                       :checkout="checkout"
                       :date-format="momentJSFormat"
                       :time-format="timeMomentJSFormat"
                       :currency-symbol="currencySymbol">
        </report-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { DateDisplayMixin } from "../Shared/Time/DateDisplayMixin";
    import { TimeDisplayMixin } from "../Shared/Time/TimeDisplayMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import PaymentReportItemMobile from "./PaymentReportItemMobile.vue";

    export default {
        name: 'PaymentReportsMobile',
        mixins: [DateDisplayMixin, TimeDisplayMixin, CountryCacheMixin],
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('PaymentCheckoutSearches', [
                'findPaymentCheckoutSearches'
            ]),
            checkoutSearches() {
                return this.findPaymentCheckoutSearches(this.searchKey);
            },
            checkouts() {
                return [].concat(...this.checkoutSearches.map(s => s.values));
            },
            loading() {
                return this.checkoutSearches.some(s => !!s.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.checkouts.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'report-mobile': PaymentReportItemMobile
        }
    }
</script>
