<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Staff Events')}}</label>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <label>{{$t('Format')}}</label>
                <div class="dropdown">
                    <button aria-expanded="false" aria-haspopup="true" class="custom-select form-control text-left text-truncate" data-toggle="dropdown" type="button">
                        {{summary}}
                    </button>
                    <ul aria-labelledby="dropdownMenuButton" class="custom-select-dropdown-menu dropdown-menu">
                        <li v-for="option in options" :key="option.id" @click="displayType=option.id" class="custom-select-dropdown-item dropdown-item pointer" v-bind:class="{ 'active selected': displayType===option.id }">
                            <span class="text-wrap">{{option.format}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { UserSettingUploadMixin } from "../../Shared/UserSettingUploadMixin";
    import { CalendarStaffEventSettingMixin } from "../../../mixins/Settings/User/CalendarStaffEventSettingMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'CalendarStaffEventSettings',
        mixins: [UserSettingUploadMixin, CalendarStaffEventSettingMixin],
        data() {
            return {
                options: [
                    {
                        "id": 0,
                        "format": "Holiday - Staff"
                    },
                    {
                        "id": 1,
                        "format": "Holiday (Staff) - Event Notes..."
                    }
                ]
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveScheduleSettingsEvent'
            ]),
            userSettingUploadEdit() {
                return {
                    displayType: this.displayType
                }
            },
            summary() {
                return this.options.find(o => o.id === this.displayType).format;
            }
        },
        watch: {
            saveScheduleSettingsEvent() {
                this.uploadUserSetting();
            },
            loadingUserSettings(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            getUserSettingValid() {
                return true;
            },
            refreshEditModel(userSettingValue) {
                this.displayType = userSettingValue.displayType;
            }
        }
    }
</script>
