import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentCheckoutSearches: [],
    paymentCheckoutSearchesCleared: 0
});

const getters = {
    findPaymentCheckoutSearch: (state) => key => state.paymentCheckoutSearches.find(pcs => pcs.key === key),
    findPaymentCheckoutSearches: (state) => (key) => state.paymentCheckoutSearches.filter(pcs => pcs.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentCheckoutSearches.find(pcs => pcs.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentCheckoutSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCheckouts(state, { key, checkouts }) {
        const existing = state.paymentCheckoutSearches.find(pcs => pcs.key === key);
        if (!!existing) {
            existing.values = checkouts;
            existing.loading = false;
        } else {
            state.paymentCheckoutSearches.push({ key: key, values: checkouts, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentCheckoutSearches.find(pcs => pcs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearPaymentCheckoutSearches(state) {
        state.paymentCheckoutSearches = [];
        state.paymentCheckoutSearchesCleared++;
    }
}

const actions = {
    searchCheckouts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.get('Checkout?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setCheckouts', { key: key, checkouts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
