<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header sub" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Client Details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="row form-group">
                    <div class="col-md-4">
                        <label for="title">{{$t('Client name')}}:</label>
                    </div>
                    <div class="col-md-8">
                        {{bookingFirstName}} {{bookingLastName}}
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('Email')}}:</label>
                    </div>
                    <div class="col-md-8">
                        {{bookingEmail}}
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('Mobile tel')}}:</label>
                    </div>
                    <div class="col-md-8">
                        {{bookingTelephoneMobile}}
                    </div>
                </div>
                <site-select :disabled="true"></site-select>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";
    import { ClientAddMixin } from "../Shared/ClientAddMixin";
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';
    import SiteSelectColumn from "../Shared/SiteSelectColumn.vue"

    export default {
        name: 'BookingAddClient',
        mixins: [ClientAddMixin, BookingCacheMixin, StatusEmitterMixin],
        props: {
            id: ''
        },
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'approveBookingEvent',
                'clientAddedEvent'
            ]),
            bookingId() {
                return !!this.id ? this.id : '';
            },
            clientAddModel() {
                return {
                    firstName: this.bookingFirstName,
                    lastName: this.bookingLastName,
                    email: this.bookingEmail,
                    telephoneMobile: this.bookingTelephoneMobile
                }
            },
            loading() {
                return !!this.addingClient;
            },
            completed() {
                return !!this.addedClientId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                };
            },
            canAddClient() {
                return !this.completed;
            }
        },
        watch: {
            approveBookingEvent() {
                this.onValidatedChanged();
            },
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddClient) {
                    return;
                }
                this.tryAddClient();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'site-select': SiteSelectColumn
        }
    }
</script>
