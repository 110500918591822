<template>
    <section>
        <div class="title-divider d-flex w-100 my-3">
            <i @click="showOrderItemForm = !showOrderItemForm"
               class="p-2 fal fa-chevron-circle-up pet-form-toggle-icon"
               v-bind:class="{'pet-form-toggle-icon-rotate':showOrderItemForm}">
            </i>
            <h2 class="p-2 mx-auto">{{orderItem.name}}</h2>
            <i @click="removeItem" class="p-2 fal fa-trash-alt"></i>
        </div>
        <template v-if="showOrderItemForm">
            <div class="row form-group">
                <div class="col-md-4">
                    <label>{{$t('Price')}} ({{currencySymbol}}):</label>
                </div>
                <div class="col-md-8">
                    <input v-model="amountInput" @blur="processInput" class="form-control text-right" type="text">
                </div>
            </div>
            <whole-number-input v-model="quantity" :title="$t('Quantity')"></whole-number-input>
            <div class="row form-group">
                <div class="col-md-4">
                    <label>{{$t('Tax')}}:</label>
                </div>
                <div class="col-md-8">
                    {{payableTaxString}}
                </div>
            </div>
        </template>
    </section>
</template>

<script>
    import { ProductInputMixin } from "../../Products/ProductInputMixin";
    import { AppointmentTotalMixin } from "../../Shared/AppointmentTotalMixin";
    import WholeNumberInput from "../../Shared/Input/WholeNumberInput.vue";

    export default {
        name: 'AppointmentOverviewOrderItem',
        mixins: [AppointmentTotalMixin, ProductInputMixin],
        props: {
            value: Array,
            index: Number
        },
        data() {
            return {
                showOrderItemForm: true
            }
        },
        computed: {
            orderItem: {
                get() {
                    return this.value[this.index];
                },
                set(value) {
                    const items = [...this.value];
                    items.splice(this.index, 1, value);
                    this.$emit('input', items);
                }
            },
            orderItems() {
                return [this.orderItem];
            },
            quantity: {
                get() {
                    return this.orderItem.quantity;
                },
                set(value) {
                    this.orderItem = Object.assign({}, this.orderItem, {
                        quantity: value
                    });
                }
            }
        },
        watch: {
            orderItem() {
                this.setInitial();
            },
            amount(value) {
                this.orderItem = Object.assign({}, this.orderItem, {
                    amount: value
                });
            }
        },
        methods: {
            setInitial() {
                this.setInitialAmount(parseFloat(this.orderItem.amount));
            },
            removeItem() {
                this.$emit('removeItem', { index: this.index, item: this.orderItem });
            }
        },
        created() {
            this.setInitial();
        },
        components: {
            'whole-number-input': WholeNumberInput
        }
    }
</script>
