/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';

export const BookingExpireMixin = {
    data() {
        return {
            error: '',
            expiring: false
        }
    },
    computed: {
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'expireBooking'
        ]),
        tryExpireBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.expiring = true;
            self.expireBooking(self.bookingId)
                .then(response => self.afterExpireBooking(response.data))
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.expiring = false);
        },
        afterExpireBooking() {
            //override if necessary
        }
    }
}
