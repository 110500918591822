<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Start day')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="custom-select form-control text-left text-truncate" data-toggle="dropdown" type="button">
                    {{$t(daysOfWeek[startDay])}}
                </button>
                <ul aria-labelledby="dropdownMenuButton" class="custom-select-dropdown-menu dropdown-menu">
                    <li v-for="id in dayIds"
                        class="custom-select-dropdown-item dropdown-item pointer"
                        :key="id"
                        @click="setStartDay(id)"
                        v-bind:class="{'active': id === startDay}">
                        <span class="text-wrap">{{$t(daysOfWeek[id])}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { DaysOfWeekMixin } from "../../Shared/DaysOfWeekMixin";
    import { DaysOrderMixin } from "../../Shared/DaysOrderMixin";

    export default {
        name: 'ScheduleStartDay',
        mixins: [DaysOrderMixin, DaysOfWeekMixin],
        props: {
            value: Number
        },
        data() {
            return {
                startDay: 0
            }
        },
        methods: {
            setStartDay(value) {
                const day = parseInt(value);
                this.startDay = day;
                this.$emit('input', isNaN(day) ? 0 : day);
            }
        },
        created() {
            this.setStartDay(this.value);
        }
    }
</script>
