<template>
    <div class="dropdown w-100">
        <button aria-expanded="false" aria-haspopup="true"
                class="filter-dd w-100 text-left d-flex justify-content-between align-items-center"
                data-toggle="dropdown" type="button">
            <div class="text-truncate">{{$t(summary)}}</div>
            <i class="far fa-chevron-down"></i>
        </button>
        <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100">
            <li v-for="option in options" :key="option.id" @click="timePeriod=option" class="dropdown-item pointer" v-bind:class="{ 'active selected': timePeriodId===option.id }">
                {{$t(option.name)}}
            </li>
        </ul>
    </div>
</template>

<script>
    import { TimePeriodMixin } from "./TimePeriodMixin";

    export default {
        name: 'TimePeriodSelector',
        mixins: [TimePeriodMixin],
        props: {
            value: Object
        },
        computed: {
            timePeriod: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            timePeriodId() {
                return !!this.timePeriod ? this.timePeriod.id : 0;
            },
            summary() {
                return !!this.timePeriod ? this.timePeriod.name : 'Unknown';
            }
        },
        created() {
            this.$emit('input', this.options[0]);
        }
    }
</script>
