import { mapState, mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { PaymentApiUserCacheMixin } from './PaymentApiUserCacheMixin';

export const PaymentSearchMixin = {
    mixins: [PaymentApiUserCacheMixin],
    data() {
        return {
            error: '',
            skip: 0,
            take: 10,
            sort: '',
            ascending: false,
            statusId: 0
        }
    },
    computed: {
        ...mapState('PaymentCheckoutSearches', [
            'paymentCheckoutSearchesCleared'
        ]),
        ...mapGetters('PaymentCheckoutSearches', [
            'findPaymentCheckoutSearch'
        ]),
        checkoutSearchModel() {
            return Object.assign({
                take: this.take,
                skip: this.skip,
                ascending: this.ascending,
                statusId: this.statusId
            }, !!this.sort ? { sort: this.sort } : {});
        },
        checkoutSearchModelValid() {
            return this.isPaymentAdmin;
        },
        checkoutSearchKey() {
            return this.isPaymentAdmin ? this.siteId + CacheHelper.objectCacheKey(this.checkoutSearchModel) : '';
        },
        checkoutSearch() {
            return this.findPaymentCheckoutSearch(this.checkoutSearchKey);
        },
        checkouts() {
            return !!this.checkoutSearch ? this.checkoutSearch.values : [];
        },
        loadingCheckoutSearch() {
            return !!this.checkoutSearch && this.checkoutSearch.loading;
        },
        pageNumber() {
            return 1 + (this.skip / this.take);
        },
        finished() {
            return this.checkouts.length < this.take;
        }
    },
    watch: {
        checkoutSearchKey() {
            this.refreshCheckoutSearch();
        },
        paymentCheckoutSearchesCleared() {
            this.refreshCheckoutSearch();
        }
    },
    methods: {
        ...mapActions('PaymentCheckoutSearches', [
            'searchCheckouts'
        ]),
        refreshCheckoutSearch() {
            if (!this.checkoutSearch || !!this.checkoutSearch.retry) {
                this.trySearchCheckouts();
            }
        },
        trySearchCheckouts() {
            const self = this;
            if (!self.checkoutSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchCheckouts({ key: self.checkoutSearchKey, payload: self.checkoutSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
        },
        next() {
            if (!this.finished) {
                this.skip += this.take;
            }
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshCheckoutSearch();
        }
    },
    created() {
        this.refreshCheckoutSearch();
    }
}
