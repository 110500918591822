<template>
    <section>
        <div class="title-divider">
            <h2>{{orderItem.name}}</h2>
        </div>
        <div class="row form-group">
            <div class="col-md-4">
                <label>{{$t('Price')}} ({{currencySymbol}}):</label>
            </div>
            <div class="col-md-8">
                <input v-model="amountInput" @blur="processInput" class="form-control text-right" type="text">
            </div>
        </div>
        <whole-number-input v-model="quantity" :title="$t('Quantity:')"></whole-number-input>
        <div class="row form-group">
            <div class="col-md-4">
                <label>{{$t('Tax')}}:</label>
            </div>
            <div class="col-md-8">
                {{payableTaxString}}
            </div>
        </div>
    </section>
</template>

<script>
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin.js';
    import { mapGetters, mapMutations } from 'vuex';
    import { ProductInputMixin } from '../../Products/ProductInputMixin.js';
    import WholeNumberInput from "../../Shared/Input/WholeNumberInput.vue";

    export default {
        name: 'AppointmentTotalOrderItem',
        mixins: [ProductInputMixin, AppointmentTotalMixin],
        props: {
            index: Number,
            itemIndex: Number
        },
        computed: {
            ...mapGetters('AppointmentScheduleUpload', [
                'indexOrderItem'
            ]),
            orderItem() {
                return this.indexOrderItem(this.index, this.itemIndex);
            },
            orderItems() {
                return [this.orderItem];
            },
            quantity: {
                get() {
                    return this.orderItem.quantity;
                },
                set(value) {
                    this.setProductQuantity({ index: this.index, itemIndex: this.itemIndex, quantity: value });
                }
            }
        },
        watch: {
            orderItem() {
                this.setInitial();
            },
            amount(newValue) {
                this.setProductAmount({ index: this.index, itemIndex: this.itemIndex, amount: newValue });
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setProductAmount',
                'setProductQuantity'
            ]),
            setInitial() {
                this.setInitialAmount(parseFloat(this.orderItem.amount));
            }
        },
        created() {
            this.setInitial();
        },
        components: {
            'whole-number-input': WholeNumberInput
        }
    }
</script>
