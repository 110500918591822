<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-7" data-toggle="collapse">
            <i class="fas fa-bell mr-2"></i>
            Notifications
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-7">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Notification sent to {{count}} Account Owner(s)!</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
                <div class="form-group">
                    <label>Message<span class="required-field"></span></label>
                    <textarea v-model="text" class="form-control" v-bind:rows="5" v-bind:disabled="loading"></textarea>
                    <validation-message v-if="!!textMessage" :message="textMessage"></validation-message>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-auto px-0 mr-2">Is Html?</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col ps-toggle-switch">
                            <input v-model="isHtml" class="toggle-checkbox" type="checkbox" v-bind:disabled="loading">
                            <div class="toggle-switch"></div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-auto px-0 mr-2">Specify Account Id?</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col ps-toggle-switch">
                            <input v-model="isSpecificAccounts" class="toggle-checkbox" type="checkbox" v-bind:disabled="loading">
                            <div class="toggle-switch"></div>
                        </label>
                    </div>
                </div>
                <account-id-multi-input v-if="!!isSpecificAccounts" v-model="accountIds" :validation-error="accountMessage"></account-id-multi-input>
                <subscription-multi-select v-else v-model="subscriptionTypeIds" :validation-error="accountMessage"></subscription-multi-select>
                <div class="form-group">
                    <button @click="add" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Add
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
        <confirmation-modal :notification="addModel" v-model="displayModalEvent" @proceed="proceed"></confirmation-modal>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";
    import SystemAdminGeneralNotificationsConfirmation from "./SystemAdminGeneralNotificationsConfirmation.vue";
    import SubscriptionMultiSelect from "../Subscriptions/SubscriptionMultiSelect.vue";
    import AccountIdMultiInput from "./AccountIdMultiInput.vue";

    export default {
        name: 'SystemAdminGeneralNotifications',
        data() {
            return {
                loading: false,
                completed: false,
                count: 0,
                error: '',
                textMessage: '',
                accountMessage: '',
                text: '<p>This is a test notification. Click here: <a href="" target="_blank">link</a></p>',
                isHtml: true,
                isSpecificAccounts: false,
                accountIds: [],
                subscriptionTypeIds: [],
                displayModalEvent: 0
            }
        },
        computed: {
            addModel() {
                return {
                    text: this.text,
                    isHtml: this.isHtml,
                    isSpecificAccounts: this.isSpecificAccounts,
                    accountIds: this.accountIds,
                    subscriptionTypeIds: this.subscriptionTypeIds
                }
            },
            addModelValid() {
                const model = this.addModel;
                return !!model.text && (model.accountIds.length > 0 || model.subscriptionTypeIds.length > 0);
            }
        },
        watch: {
            accountIds(value) {
                if (value.length > 0) {
                    this.accountMessage = ''
                }
            },
            subscriptionTypeIds(value) {
                if (value.length > 0) {
                    this.accountMessage = ''
                }
            }
        },
        methods: {
            add() {
                const self = this;
                self.validate();
                if (self.addModelValid) {
                    this.displayModalEvent++
                }
            },
            proceed() {
                const self = this;
                self.completed = false;
                self.error = '';
                self.validate();
                if (!self.addModelValid) {
                    return;
                }
                self.loading = true;
                Api.post('General/SystemAdmin/Notification', self.addModel)
                    .then(response => {
                        self.completed = true;
                        self.count = response.data.count;
                        self.clearAddModel();
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            clearAddModel() {
                this.accountIds = [];
                this.subscriptionTypeIds = [];
            },
            validate() {
                const self = this;
                const model = self.addModel
                self.textMessage = !!model.text ? '' : 'Please enter notification message.';
                self.accountMessage = !!model.isSpecificAccounts ?
                    model.accountIds.length > 0 ? '' : 'Please enter account id of owners.' :
                    model.subscriptionTypeIds.length > 0 ? '' : 'Please select subscrition type of owners.'
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'confirmation-modal': SystemAdminGeneralNotificationsConfirmation,
            'subscription-multi-select': SubscriptionMultiSelect,
            'account-id-multi-input': AccountIdMultiInput
        }
    }
</script>
