<template>
    <div class="content">
        <table-mobile>
            <site-mobile v-for="site in sites" :key="site.id" :site="site"></site-mobile>
        </table-mobile>
        <table-desktop>
            <template #header>
                <tr role="row">
                    <th aria-controls="clients-table" class="col-2 text-left">{{$t('Site')}}</th>
                    <th aria-controls="clients-table" class="col-2 text-left">{{$t('Company')}}</th>
                    <th aria-controls="clients-table" class="col-2 text-left">{{$t('Country')}}</th>
                    <th aria-controls="clients-table" class="col-2 text-right">{{$t('Campaign')}}</th>
                    <th aria-controls="clients-table" class="col-2 text-right">{{$t('SMS')}}</th>
                    <th aria-controls="clients-table" class="col-2 text-right">{{$t('Tax Rate')}} (%)</th>
                </tr>
            </template>
            <template #rows>
                <site-desktop v-for="(site, index) in sites" :site="site" :number="index" :key="site.id"></site-desktop>
            </template>
        </table-desktop>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import TableDesktop from "../Shared/Layout/TableDesktop.vue";
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import SiteItemDesktop from "./SiteItemDesktop.vue";
    import SiteItemMobile from "./SiteItemMobile.vue";

    export default {
        name: 'SiteaTable',
        mixins: [SiteCacheMixin],
        components: {
            'table-desktop': TableDesktop,
            'site-desktop': SiteItemDesktop,
            'table-mobile': TableMobile,
            'site-mobile': SiteItemMobile
        }
    }
</script>
