<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapseDeposit" data-toggle="collapse">
            {{$t('Deposit')}}
            <div class="total-figure ml-auto"></div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapseDeposit">
            <div class="card card-body">
                <section class="group">
                    <toggle-input v-model="isRequired" :title="$t('Deposit Required?')"></toggle-input>
                    <template v-if="isRequired">
                        <provider-select v-if="isPaymentAdmin" v-model="providerTypeId" :validation="checkoutValidator.providerTypeId"></provider-select>
                        <money-input v-model="amount" :title="$t('Amount')"></money-input>
                        <whole-number-input v-model="dueDaysPrior" :title="$t('Due days prior')"></whole-number-input>
                    </template>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
    import { DepositCalculatorMixin } from "../../../mixins/Payments/DepositCalculatorMixin";
    import { PaymentCheckoutsAddMixin } from "../../Payments/PaymentCheckoutsAddMixin";
    import MoneyInput from "../../Shared/Input/MoneyInput.vue";
    import { mapState } from 'vuex';
    import CheckoutProviderSelect from "../../Payments/CheckoutProviderSelect.vue";
    import { AppointmentDepositAddMixin } from "../../../mixins/AppointmentDeposits/AppointmentDepositAddMixin";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import moment from 'moment';
    import WholeNumberInput from "../../Shared/Input/WholeNumberInput.vue";
    import { PaymentApiUserCacheMixin } from "../../../mixins/Payments/PaymentApiUserCacheMixin";

    export default {
        name: 'AppointmentDepositInput',
        mixins: [DepositCalculatorMixin, PaymentCheckoutsAddMixin, PaymentApiUserCacheMixin, AppointmentDepositAddMixin],
        data() {
            return {
                addedSchedule: null
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'appointmentScheduleAddedEvent'
            ]),
            ...mapState('AppointmentScheduleUpload', [
                'startUtc'
            ]),
            scheduleId() {
                return !!this.addedSchedule ? this.addedSchedule.id : '';
            },
            checkoutsReference() {
                return !!this.scheduleId ? this.scheduleId + '0' : '';
            },
            defaultRequirement() {
                return this.settingObject.isRequired;
            },
            defaultHasDueDate() {
                return !!this.settingObject.hasDueDate;
            },
            defaultDaysPrior() {
                return this.settingObject.daysPrior;
            }
        },
        watch: {
            appointmentScheduleAddedEvent(appointmentSchedule) {
                this.addedSchedule = appointmentSchedule;
                this.tryAddAppointmentDeposit();
                if (this.isRequired) {
                    const daysPrior = parseInt(this.dueDaysPrior);
                    this.dueUtc = isNaN(daysPrior) ? null : moment(this.startUtc).subtract(daysPrior, 'd').utc().format();
                    this.tryAddCheckout();
                }
            },
            settingObject() {
                this.refreshDefaults();
            }
        },
        methods: {
            tryFindCheckouts() {
                // do nothing
            },
            refreshDefaults() {
                this.isRequired = this.defaultRequirement;
                this.dueDaysPrior = this.defaultHasDueDate ? this.defaultDaysPrior : null;
            }
        },
        components: {
            'money-input': MoneyInput,
            'provider-select': CheckoutProviderSelect,
            'toggle-input': SlottedToggle,
            'whole-number-input': WholeNumberInput
        },
        created() {
            this.refreshDefaults();
        }
    }
</script>
