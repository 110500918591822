<template>
    <section class="group">
        <div class="row form-group">
            <div class="col-md-4">
                <label>{{$t('Payment type')}}</label>
            </div>
            <div class="col-md-8">
                <label class="custom-control custom-radio custom-radio-inline">
                    <input v-model="paymentMethod" value="1" class="custom-control-input" name="radio-example" type="radio">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{$t('Cash')}}</span>
                </label>
                <label class="custom-control custom-radio custom-radio-inline">
                    <input v-model="paymentMethod" value="2" class="custom-control-input" name="radio-example" type="radio">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{$t('Card')}}</span>
                </label>
                <label class="custom-control custom-radio custom-radio-inline">
                    <input v-model="paymentMethod" value="3" class="custom-control-input" name="radio-example" type="radio">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{$t('Invoice')}}</span>
                </label>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'AppointmentPaymentSelect',
        computed: {
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            appointmentOrder() {
                return this.indexAppointmentOrder(0);
            },
            paymentMethod: {
                get() {
                    return this.appointmentOrder.paymentMethodId;
                },
                set(value) {
                    this.setPaymentMethod(value);
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setPaymentMethod'
            ])
        }
    }
</script>
