<template>
    <div class="row form-group" v-show="appointmentDates.length > 0">
        <div class="col-md-4">
            <label for="title">{{$t('Appointment Dates')}}</label>
        </div>
        <div class="col-md-8">
            <div class="d-flex align-items-start flex-column mw-400">
                <div class="d-flex justify-content-between">
                    <p class="mb-0">1. {{appointmentDates[0]}}</p>
                    <i v-show="appointmentDates.length > 1"
                       ref="appointment-dates-ref" aria-expanded="false"
                       data-target="#appointment-dates"
                       data-toggle="collapse"
                       class="far fa-chevron-circle-down appointment-notifications-icon ml-2">
                    </i>
                </div>
                <div class="collapse" id="appointment-dates">
                    <template v-for="(date, index) in appointmentDates">
                        <p v-show="index != 0" class="mb-0">{{index + 1}}. {{date}}</p>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import moment from "moment-timezone";
    import { TimeZoneMixin } from "../../Shared/Time/Zone/TimeZoneMixin"
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin';

    export default {
        name: 'AppointmentOptionsDates',
        mixins: [TimeZoneMixin, DateDisplayMixin],
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'startUtc',
                'appointmentFrequencyId',
                'frequencyMultiple',
                'occurrences'
            ]),
            appointmentDates() {
                const frequencyUnits = {
                    0: null, // No repeat
                    1: "weeks", // Week(s)
                    2: "months", // Month(s)
                    3: "years", // Year(s)
                };

                const unit = frequencyUnits[this.appointmentFrequencyId];

                if (!unit || this.occurrences < 1) {
                    return [];
                }

                var startUtc = moment.tz(this.startUtc, "UTC");

                const dates = [];

                let startZoned = startUtc.tz(this.selectedTimeZoneId);

                dates.push(`${startZoned.format(this.momentJSFormat)} - ${startZoned.format('dddd')}`);

                for (let i = 1; i < this.occurrences; i++) {

                    const newDate = moment(startZoned).add(this.frequencyMultiple, unit);

                    dates.push(`${newDate.format(this.momentJSFormat)} - ${newDate.format('dddd')}`);

                    startZoned = newDate;
                }

                return dates;
            }
        }
    };
</script>

