<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-sitemap fa-1x"></i>
                    {{$t('Sites')}} <small>({{siteCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <nav class="links">
                    <ul>
                        <li class="pointer" @click="toggleShowAppSettings" v-bind:class="{ active: !showAppSettings}">
                            <a>{{$t('Sites')}}</a>
                        </li>
                        <li class="pointer" @click="toggleShowAppSettings" v-bind:class="{ active: showAppSettings}">
                            <a>{{$t('Default Settings')}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="openSiteAdd" class="mobile-header-btn" v-bind:disabled="fetchingSiteAddAllowed">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add site</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <button @click="openSiteAdd" class="btn-add btn btn-secondary d-none d-md-block" v-bind:disabled="fetchingSiteAddAllowed">
                    <i class="fas fa-plus"></i>
                    {{$t('Add site')}}
                </button>
            </div>
        </header>
        <sites-table v-if="!showAppSettings"></sites-table>
        <app-settings v-else></app-settings>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import { SiteCountCacheMixin } from '../Shared/SiteCountCacheMixin';
    import { SiteAddAllowedMixin } from '../Shared/SiteAddAllowedMixin';
    import SitesTable from './SitesTable.vue';
    import AppSettings from '../Settings/AppSettings.vue';

    export default {
        name: 'Sites',
        mixins: [SiteCacheMixin, SiteCountCacheMixin, SiteAddAllowedMixin],
        data() {
            return {
                showAppSettings: false
            }
        },
        computed: {
            siteCountString() {
                return this.siteCount ? this.siteCount.count : '-';
            }
        },
        methods: {           
            toggleShowAppSettings() {
                this.showAppSettings = !this.showAppSettings;
            }
        },
        components: {
            'sites-table': SitesTable,
            'app-settings': AppSettings
        }
    }
</script>
