import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BookingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Bookings', [
            'findBookingCache'
        ]),
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        },
        bookingCache() {
            return this.findBookingCache(this.bookingId);
        },
        booking() {
            return !!this.bookingCache ? this.bookingCache.value : null;
        },
        loadingBooking() {
            return !!this.bookingCache && this.bookingCache.loading;
        },
        bookingFullname() {
            return !!this.booking ? this.booking.firstName + ' ' + this.booking.lastName : '-';
        },
        bookingFirstName() {
            return !!this.booking ? this.booking.firstName : '';
        },
        bookingLastName() {
            return !!this.booking ? this.booking.lastName : '';
        },
        bookingTelephoneMobile() {
            return !!this.booking ? this.booking.telephoneMobile : '';
        },
        bookingEmail() {
            return this.booking ? this.booking.email : '';
        },
        bookingParticipants() {
            return !!this.booking ? this.booking.participants : [];
        },
        bookingStatusId() {
            return !!this.booking ? this.booking.status.id : 0;
        },
        bookingCanApprove() {
            return !!this.booking ? this.booking.canApprove : false;
        },
        bookingStatusCreated() {
            return this.bookingStatusId === 1;
        },
        bookingStatusDeclined() {
            return this.bookingStatusId === 3;
        },
        changeStatusToExpiredAllowed() {
            return this.bookingStatusCreated && !this.bookingCanApprove
        },
        bookingStatusClass() {
            switch (this.bookingStatusId) {
                case 1:
                    return 'alert-info';
                case 2:
                    return 'alert-success';
                case 3:
                    return 'alert-danger';
                case 4:
                    return 'alert-warning';
                default:
                    return '';
            }
        },
        bookingStatusDescription() {
            return this.booking ? this.booking.status.description.toUpperCase() : "-";
        }
    },
    watch: {
        bookingId() {
            this.refreshBooking();
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'getBooking'
        ]),
        refreshBooking() {
            if (!this.bookingCache || !!this.bookingCache.retry) {
                this.tryGetBooking();
            }
        },
        tryGetBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.getBooking(self.bookingId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBooking();
    }
}
