<template>
    <section class="mb-3">
        <div v-if="!!setting" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Deposit Settings')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group">
                <toggle-input v-model="isRequired" :title="$t('Deposit Required?')"></toggle-input>
                <template v-if="isRequired">
                    <toggle-input v-model="isPercentage" :title="$t('Percentage?')"></toggle-input>
                    <number-input v-if="isPercentage" v-model="percentage" :title="`${$t('Amount')} (%)`"></number-input>
                    <template v-else>
                        <money-input v-model="amount" :title="$t('Amount')"></money-input>
                    </template>
                    <toggle-input v-model="hasDueDate" :title="$t('Has due date?')"></toggle-input>
                    <template v-if="hasDueDate">
                        <whole-number-input v-model="daysPrior" :title="$t('Due days prior')"></whole-number-input>
                    </template>
                </template>
            </div>
        </template>
    </section>
</template>

<script>
    import MoneyInput from "../../Shared/Input/MoneyInput.vue";
    import NumberInput from "../../Shared/Input/NumberInput.vue";
    import { mapState } from 'vuex';
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import { SiteDepositSettingUploadMixin } from "../../../mixins/Settings/Site/SiteDepositSettingUploadMixin";
    import WholeNumberInput from "../../Shared/Input/WholeNumberInput.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'SiteAddDeposit',
        mixins: [SiteDepositSettingUploadMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            canAddDepositSetting() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !this.settingEditModelModified || !!this.setting;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingSetting
                }
            }
        },
        watch: {
            canAddDepositSetting(value) {
                if (value) {
                    this.tryAddSetting();
                }
            },
            saveSiteEvent() {
                if (this.canAddDepositSetting) {
                    this.tryAddSetting();
                }
            }
        },
        methods: {
            tryGetSetting() {
                // do nothing
            },
            refreshSettingEditModel() {
                // do nothing
            }
        },
        components: {
            'money-input': MoneyInput,
            'toggle-input': SlottedToggle,
            'number-input': NumberInput,
            'whole-number-input': WholeNumberInput,
            'error-banner': ErrorBanner
        }
    }
</script>
