<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-store fa-1x"></i>
                    {{$t('Store')}}
                </h1>
            </div>
            <div class="secondary-nav p-0">
                <nav class="links">
                    <ul>
                        <li class="pointer" @click="selectStoreTab(0)" v-bind:class="{ active: storeTabNumber === 0}">
                            <a class="px-2">{{$t('SMS')}}</a>
                        </li>
                        <li class="pointer" @click="selectStoreTab(1)" v-bind:class="{ active: storeTabNumber === 1}">
                            <a class="px-2">{{$t('Campaigns')}}</a>
                        </li>
                        <li class="pointer" @click="selectStoreTab(2)" v-bind:class="{ active: storeTabNumber === 2}">
                            <a class="px-2">{{$t('Email Templates')}}</a>
                        </li>
                        <li v-if="showUpgrades" class="pointer" @click="selectStoreTab(3)" v-bind:class="{ active: storeTabNumber === 3}">
                            <a class="px-2">{{$t('Subscription')}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <error-banner v-if="storeError" :message="storeError"></error-banner>
            <div v-if="storeWarning" class="alert alert-warning  alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{ storeWarning }}</p>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <i class="fal fa-times"></i>
                </button>
            </div>
            <div v-if="storeSuccess" class="alert alert-success alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{ storeSuccess }}</p>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <i class="fal fa-times"></i>
                </button>
            </div>
            <sms-pack v-if="storeTabNumber === 0"></sms-pack>
            <campaign-pack v-if="storeTabNumber === 1"></campaign-pack>
            <templates-gallery v-if="storeTabNumber === 2"></templates-gallery>
            <subscription-upgrade v-if="storeTabNumber === 3 && showUpgrades"></subscription-upgrade>
        </div>
    </div>
</template>

<script>
    import StripoDefaultTemplateSettingsDisplay from "../Communication/Campaign/StripoDefaultTemplateSettingsDisplay.vue"
    import SubscriptionUpgrade from "../Subscriptions/SubscriptionUpgrade.vue"
    import SmsPack from '../SmsCredits/SmsPack.vue';
    import CampaignPack from "../CampaignCredits/CampaignPack.vue";
    import SiteSelect from "../Account/SiteSelect.vue";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import { SubscriptionSettingsCacheMixin } from "../../mixins/Subscriptions/SubscriptionSettingsCacheMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'Store',
        mixins: [SubscriptionSettingsCacheMixin],
        data() {
            return {
                storeTabNumber: 0
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'storeTabChange'
            ]),
            storeError() {
                return this.$route.query.error;
            },
            storeWarning() {
                return this.$route.query.warning;
            },
            storeSuccess() {
                return this.$route.query.success;
            }
        },
        watch: {
            storeTabChange(value) {
                this.selectStoreTab(value.tab);
            }
        },
        methods: {
            selectStoreTab(value) {
                const tab = parseInt(value);
                this.storeTabNumber = isNaN(tab) ? 0 : tab;
            }
        },
        components: {
            'templates-gallery': StripoDefaultTemplateSettingsDisplay,
            'subscription-upgrade': SubscriptionUpgrade,
            'sms-pack': SmsPack,
            'campaign-pack': CampaignPack,
            'site-select': SiteSelect,
            'error-banner': ErrorBanner
        },
        created() {
            this.selectStoreTab(this.$route.query.tab);
        }
    }
</script>
