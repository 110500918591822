<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="!subscriptionFeatureAllowed" class="alert alert-info alert-icon mt-2 mb-2" role="alert">
            <div class="alert-content">
                <p>{{$t('To unlock the ability to send an email to a client, upgrade your subscription to a paid plan.')}}</p>
            </div>
            <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div v-if="communicationSuccessful" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Sent')}}!</p>
            </div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="fal fa-times"></i>
            </button>
        </div>
        <section class="rounded-card-white mb-3">
            <slotted-input v-model="subject" :title="$t('Subject')" :disabled="!subscriptionFeatureAllowed">
                <label v-if="clientMessageAddValidator.subject" class="error">{{clientMessageAddValidator.subject}}</label>
            </slotted-input>
            <slotted-text v-model="body" :title="$t('Message')" :rows="10" :max-length="1000" :disabled="!subscriptionFeatureAllowed">
                <label v-if="clientMessageAddValidator.body" class="error">{{clientMessageAddValidator.body}}</label>
            </slotted-text>
        </section>
        <div class="form-group">
            <button @click="trySendClientMessage" v-bind:disabled="loading || !subscriptionFeatureAllowed" class="btn btn-primary">
                {{$t('Send')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { ClientMessageCommunicationMixin } from "../../../mixins/ClientMessages/ClientMessageCommunicationMixin";
    import { SubscriptionFeatureMixin } from "../../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import SlottedInput from "../../Shared/Input/SlottedInput.vue";
    import SlottedTextArea from "../../Shared/Input/SlottedTextArea.vue";

    export default {
        name: 'ClientEmailSend',
        mixins: [ClientMessageCommunicationMixin, SubscriptionFeatureMixin],
        props: {
            client: {
                type: Object,
                required: true
            }
        },
        computed: {
            clientId() {
                return this.client.id;
            },
            methodTypeId() {
                return 1;
            },
            computedFeatureKey() {
                return 'genericMessaging';
            }
        },
        components: {
            'slotted-input': SlottedInput,
            'slotted-text': SlottedTextArea,
            'error-banner': ErrorBanner
        }
    }
</script>
