<template>
    <div class="form-group row">
        <div class="col-md-4">
        </div>
        <div class="col-md-8">
            <div class="mw-400 w-100">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="success" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>{{$t('Notification sent!')}}</p>
                    </div>
                    <button @click="success=false" type="button" class="close ali">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <label>{{$t('Send notification')}}</label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <textarea v-model="text" class="form-control" type="text" maxlength="200" />
                <div class="d-flex justify-content-between">
                    <div>
                        <validation-message v-if="notificationAddValidator.text" :message="$t(notificationAddValidator.text)"></validation-message>
                    </div>
                    <button @click="tryAddNotification" class="btn btn-sm btn-outline mt-2" v-bind:disabled="addingNotification">
                        <span v-if="addingNotification" class="inline-loader"></span>
                        <template v-else>
                            <i class="fal fa-paper-plane"></i>
                            {{$t('Send')}}
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { NotificationAddMixin } from "./NotificationAddMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'NotificationSend',
        mixins: [NotificationAddMixin],
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
