<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{ title }}</label>
        </div>
        <div class="col-md-8">
            <input v-model.number="amount"
                   class="form-control text-right"
                   type="number"
                   min="0" />
        </div>
    </div>
</template>

<script>export default {
        name: 'WholeNumberInput',
        props: {
            value: Number,
            title: String
        },
        computed: {
            amount: {
                get() {
                    return this.value;
                },
                set(value) {
                    const parsedValue = parseInt(value, 10);
                    this.$emit('input', isNaN(parsedValue) || parsedValue < 0 ? 0 : parsedValue);
                }
            }
        }
    };</script>
